import React, {
	createContext,
	JSXElementConstructor,
	useContext,
	useEffect,
	useLayoutEffect,
} from 'react';
import { RouteObject } from 'react-router/dist/lib/context';
import { Route, Navigation } from '../models/route';
import { PrizeSession, PrizeSteps } from '../models/api/prize-session';
import { Deployment } from '../models/api/deployment';
import { Promotion } from '../models/promotion';
import { Cookies } from '../models/cookies';

export const DarkModeContext = createContext<{ darkMode: boolean; onToggle: () => void }>({
	darkMode: false,
	onToggle: () => null,
});

export const useDarkMode = () => useContext(DarkModeContext);

export const RoutesContext = createContext<
	Partial<{
		routes: Route[];
		routerRoutes: RouteObject[];
		routeStack: Route[];
		currentRouteItem?: Route | null;
		flatRoutes: Route[];
		navigation: Navigation;
		router: React.ReactElement<any, string | JSXElementConstructor<any>> | null;
	}>
>({
	routes: [],
	routerRoutes: [],
	routeStack: [],
	currentRouteItem: null,
	flatRoutes: [],
	navigation: [],
	router: null,
});

export const useRoutes = () => useContext(RoutesContext);

export const SidebarContext = createContext<
	Partial<{
		open: boolean | null;
		minimised: boolean;
		onTemporaryMinimise: () => void;
		onMinimiseToggle: () => void;
		onToggle: () => void;
		mobileOpen: boolean;
		onMobileToggle: () => void;
	}>
>({});

export const useSidebar = () => useContext(SidebarContext);

export const LayoutContext = createContext<{ $toolbar: HTMLElement | null }>({ $toolbar: null });
export const useLayout = () => useContext(LayoutContext);

interface PrizeContextType {
	promotion: Promotion | undefined;
	opened: boolean;
	session: PrizeSession;
	activeStep?: PrizeSteps;
	setStep?: (step: PrizeSteps) => void;
	setSession: (session: PrizeSession) => void;
}

export const PrizesContext = createContext<PrizeContextType>({} as PrizeContextType);

export const CookiePolicyContext = React.createContext<Cookies>({
	types: {
		analytical: false,
		performance: false,
		operational: true,
	},
	acknowledged: false,
	review: false,
	show: () => null,
});

export const useCookies = (): Cookies => {
	const cookies: Cookies = useContext(CookiePolicyContext);
	return cookies;
};

export const usePrizes = () => useContext(PrizesContext);

export const LanguageContext = createContext<{
	language: string;
}>({
	language: '',
});

export const useLanguage = () => useContext(LanguageContext);

export const KioskContext = createContext<{ kiosk: boolean }>({
	kiosk: false,
});

export const useKiosk = () => useContext(KioskContext);

export const PrizeGrabContext = createContext<
	Partial<{
		transitions: { grabber: string; prizeBall: string };
		animationTimings: {
			descend: number;
			claws: number;
			slideToStart: number;
			prizeBall: number;
		};
		grabberPosition: number;
		complete: boolean;
		inProgress: boolean;
		descend: boolean;
		pickingUpBall: boolean;
		pickedUpBall: boolean;
		dropBall: boolean;
		pickUpBall: () => void;
		onChangeGrabberPosition: (event: Event, newGrabberPosition: number) => void;
		reset: () => void;
	}>
>({
	transitions: { grabber: '', prizeBall: '' },
	animationTimings: {descend: 0, claws: 0, slideToStart: 0, prizeBall: 0},
	grabberPosition: 0,
	complete: false,
	inProgress: false,
	descend: false,
	pickingUpBall: false,
	pickedUpBall: false,
	dropBall: false,
	pickUpBall: () => null,
	onChangeGrabberPosition: () => null,
	reset: () => null
});

export const usePrizeGrab = () => useContext(PrizeGrabContext);

export const RootElementContext = createContext<HTMLElement | null>(null);

export const useRootElement = () => useContext(RootElementContext);
